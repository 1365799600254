
    import { defineComponent } from 'vue';
    import { IonCard, IonCardHeader, IonCardTitle, IonImg, IonCardContent, IonIcon, IonLabel, IonButton, IonContent } from '@ionic/vue';
    import { calendar, locationSharp, ticketOutline, caretDownOutline, heartOutline, heart } from 'ionicons/icons';
    import { store } from '../../store/store';
    import { EventModel, EventCategoryModel, EventApiClient } from '../../generated';
    import moment from 'moment';
    import { SharedMixin } from '../../mixins/SharedMixin';
    import { LanguageMixin } from '@/mixins/LanguageMixin';

    export default defineComponent({
        name: 'Events Detail',
        mixins: [SharedMixin, LanguageMixin],
        components: {
            IonCard,
            IonCardHeader,
            IonCardTitle,
            IonImg,
            IonCardContent,
            IonIcon,
            IonLabel,
            IonButton,
            IonContent
        },
        data() {
            return {
                eventdetailId: this.$route.params.eventdetail as string,
                eventId: this.$route.params.event as string,
                currentEvent: new EventCategoryModel(),
                currentEventDate: new EventModel(),
                loaded: false,
                cal: '',
                currentLanguage: store.state.currentLanguage as string,
                favoriteEvents: localStorage.getItem("favoriteEvents"),
                loggedIn(): boolean {
                    return localStorage.getItem('loggedIn') == 'true';
                },
                eventDetailPageShow: false,
                timeUntil: true,
                showPrice: true
            }
        },
        methods: {
            createICS: function (eventDate: EventModel) {
                const currentCulture = store.state.currentLanguage;
                const eventDateId = eventDate.id;
                const eventApi = new EventApiClient();

                eventApi.addToCalendar(eventDateId, currentCulture).then((result) => {
                    if (result !== null) {
                        const blob = new Blob([result.data], { type: 'text/calendar' });
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = 'event.ics';
                        link.click();
                    }
                },
                    (error) => {
                        console.log(error);
                    });
                return;
            },
            toggleExpand: function (e: number) {
                const expandHeader = document.querySelectorAll('[data-expand="expand-' + e + '"]')[0] as HTMLDivElement,
                    newHeight = expandHeader.nextElementSibling ? expandHeader.nextElementSibling.querySelector('.expand-content')?.clientHeight : null,
                    contentContainer = expandHeader.nextElementSibling as HTMLCanvasElement;

                expandHeader.classList.toggle('active');

                const page = document.querySelector('ion-content #page') as HTMLIonContentElement;
                const lengthPage = document.querySelector('#scrollPage') as HTMLIonCardElement;
                const scrollLocation = lengthPage.clientHeight - expandHeader.offsetTop + window.innerHeight;
                page.scrollToPoint(0, scrollLocation, 1500);

                if (expandHeader.nextElementSibling && contentContainer.style.height === '') {
                    contentContainer.style.height = newHeight + 'px';
                } else {
                    contentContainer.style.height = '';
                }

                //closing other accordion items
                if (this.currentEventDate.info) {

                    const nonClickedIndexes = this.currentEventDate.info.filter((item) => item.id != e);

                    for (let i = 0; i < nonClickedIndexes.length; i++) {
                        if (nonClickedIndexes[i].id != e) {
                            const closeOtherHeaders = document.querySelectorAll('[data-expand="expand-' + nonClickedIndexes[i].id + '"]')[0],
                                contentContainerClosed = closeOtherHeaders.nextElementSibling as HTMLCanvasElement;

                            closeOtherHeaders.classList.remove('active');
                            contentContainerClosed.style.height = '';
                        }
                    }
                }
            },
        },
        computed: {
            getDate(): string {
                return this.currentEventDate.timeFrom != undefined ?
                    this.getDateString(this.currentEventDate.timeFrom, 'DD MM YY') == this.getDateString(this.currentEventDate.timeUntil, 'DD MM YY') || this.getDateString(this.currentEventDate.timeUntil, 'YYYY') == "0001" ?
                        this.firstLetterToUppercase(this.getDateString(this.currentEventDate.timeFrom, 'dddd, D MMM YYYY')) :
                        this.firstLetterToUppercase(this.getDateString(this.currentEventDate.timeFrom, 'dddd, D MMM YYYY')) + ' - ' + this.firstLetterToUppercase(this.getDateString(this.currentEventDate.timeUntil, 'dddd, D MMM YYYY')) : ''
            },
            getImage(): string {
                return this.currentEvent.imageUrl != undefined ? this.currentEvent.imageUrl : '';
            }
        },
        setup() {

            function getDateString(date: Date, format: string): string {
                return moment(date).format(format);
            }

            function firstLetterToUppercase(word: string): string {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }

            return {
                calendar,
                locationSharp,
                ticketOutline,
                getDateString,
                caretDownOutline,
                firstLetterToUppercase,
                heartOutline,
                heart
            }
        },
        mounted() {

            //setting 'moment' to the right language, default NL
            const language = this.currentLanguage;
            switch (language) {
                case "nl-NL" || "nl-BE":
                    moment.locale('nl');
                    break;
                case "fr-BE" || "fr-LU":
                    moment.locale('fr');
                    break;
                default:
                    moment.locale('nl');
            }

            if (this.eventId) {
                const eventsClient = new EventApiClient();
                eventsClient.get(parseInt(this.eventId), store.state.currentLanguage).then(result => {
                    console.log(result);
                    if (result != null) {
                        this.currentEvent = result;

                        if (this.currentEvent.eventDates != undefined) {
                            const eventDates = this.currentEvent.eventDates.filter(e => e.id === parseInt(this.eventdetailId));
                            this.currentEventDate = eventDates[0];
                            console.log(this.currentEventDate)
                            console.log(this.currentEventDate.location?.googleMapsLink)

                            //Nullcheck for timeUntil date (if year is 0001, date has not been entered)
                            const { timeUntil } = this.currentEventDate;
                            const yearUntil = timeUntil.toString().split(" ")[3]
                            if (yearUntil === "0001") {
                                this.timeUntil = false;
                            }
                        }
                    }
                    this.showPrice = this.currentEventDate.timeFrom > new Date();
                });
            }
            
        }

    });
